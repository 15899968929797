@import url(https://fonts.googleapis.com/css2?family=Inter&display=swap);
.login-page {
  width: 100%;
  height: inherit;
}
.login-form,
.pairScanner-form {
  width: 100%;
  display: block;
  margin: auto;
  height: inherit;
}
.login-container,
.pairScanner-container {
  position: relative;
  margin: 0 auto 0px;
  display: block;
  background-color: #f8f9fa;
  height: inherit;
  width: 90%;
  text-align: left;
}
.login-form-box,
.pairScanner-form-box {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding: 18px;
  display: block;
  margin: auto;
  padding-top: 5vh;
}
.pairScanner-btm-container {
  width: 100%;
  height: 149px;
  position: relative;
}
.login-btm-container {
  width: 100%;
}
.login-app-logo,
.pairScanner-app-logo {
  display: block;
  width: 106px;
  margin: auto;
}
.login-form-title,
.pairScanner-form-title {
  font-size: 32px;
  font-weight: bolder;
  padding: 36px 0px 0;
  color: #171717;
  text-transform: capitalize;
  text-align: center;
}
.form-username-group,
.form-password-group,
.form-scannerId-group {
  margin-bottom: 12px;
}
.form-username,
.form-password,
.form-scannerId,
.form-pin {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #cecece;
  width: 100%;
  height: 40px;
  padding: 6px;
  box-sizing: border-box;
  margin-top: 15px;
}
.login-username-lable,
.login-password-lable,
.pair-scanner-lable {
  font-size: 14px;
  margin: 0;
  color: #333333;
}
.button {
  height: 56px;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  display: block;
  font-size: 16px;
  background: #0067ff;
  border-radius: 14px;
  bottom: 100px;
  width: 100%;
  margin-bottom: 15px;
}
.form-error-msg {
  font-size: 12px;
  color: #bf0603;
  font-weight: bold;
}
.unpair-scanner {
  width: 90%;
  display: flex;
  justify-content: right;
}
.unpair-scanner-btn {
  background-color: transparent;
  border: none;
  color: #ffffff;
  text-transform: uppercase;
  padding: 8px 0px;
  letter-spacing: 1px;
}
.confirm-dialog.unpair {
  top: 50%;
  transform: translateY(-50%);
  width: 90%;
  font-size: 16px;
}

/* new */
.login-form-desc,
.pairScanner-form-desc {
  height: 40px;
  max-width: 320px;
  left: 48px;
  top: 315px;
  margin: auto;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #495057;
  padding: 15px 0;
  margin-bottom: 30px !important;
}
.pairScanner-form-desc {
  max-width: 200px;
}
input::placeholder {
  font-size: 16px;
}
.button:disabled {
  background: #66a3ff;
  color: rgba(255, 255, 255, 0.3);
}
input:focus-visible {
  outline: none;
}
/* pagination */
.pagination {
  bottom: 60px;
  text-align: center;
  margin: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}
.dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #cce0ff;
  margin: 5px;
  border-radius: 100px;
}
.dot.active {
  width: 12px;
  height: 12px;
  background: #0052cc;
}
.pagination-text {
  bottom: 35px;
  text-align: center;
  margin: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  margin-bottom: 20px;
}
.toast-msg {
  position: absolute;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  height: 44px;
  color: #ffffff;
  line-height: 20px;
  font-weight: 600;
  left: -5vw;
}
.toast-msg.success,
.toast-msg.success {
  background: #2aca59;
}
.toast-msg.fail,
.toast-msg.fail {
  background: #ff1842;
}

/* msg box */
.login-msg-box {
  display: flex;
  align-items: center;
  padding-top: 20px;
}
.scanner-details {
  padding-left: 8px;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.75px;
  color: #495057;
}
.unpair-btn {
  margin-left: auto;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  letter-spacing: 0.75px;
  color: #0067ff;
  cursor: pointer;
}

.syncProfile-form {
  width: 100%;
  display: block;
  margin: auto;
}
.syncProfile-container {
  position: relative;
  margin: 0 auto 0px;
  display: block;
  background-color: #F8F9FA;
  height: inherit;
  width: 90%;
  text-align: left;
}
.syncProfile-form-box {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  padding: 18px;
  display: block;
  margin: auto;
  top: 10vh;
}
.syncProfile-form-title {
  font-size: 32px;
  font-weight: bolder;
  padding: 36px 0px 0;
  color: #171717;
  text-transform: capitalize;
  text-align: center;
  margin-top: 106px;
}
.syncProfile-form-desc {
  height: 40px;
  max-width: 320px;
  left: 48px;
  top: 315px;
  margin: auto;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #495057;
  padding: 15px 0;
  margin-bottom: 30px !important;
}
/* msg box */
.sync-msg-box {
  display: flex;
  align-items: center;
  padding-top: 30px;
  justify-content: center;
}
.syncProfile-details {
  padding-left: 8px;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.75px;
  color: #495057;
}
.syncProfile-progress-percent {
  color: #A2A2A2;
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
}
.syncProfile-progress-percent.right {
  text-align: right;
}
.syncProfile-form-box .progress {
  border-radius: 100px;
  height: 20px;
  background-color: #385682;
}
.syncProfile-form-box .progress-bar {
  background-color: #5297FF;
}
.sync-msg {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  bottom: 38px;
  height: 44px;
  color: #FFFFFF;
  line-height: 20px;
  font-weight: 600;
  text-align: center;
  left: 0;
}
.sync-success {
  background: #2ACA59;
}
.sync-fail, .toast-msg.sync.fail {
  background: #FF1842;
  white-space: pre-line;
  height: 61px;
}
.toast-msg.sync {
  bottom: 38px;
  left: 0;
  text-align: center;
}
.event-form {
  width: 100%;
  display: block;
  margin: auto;
  height: inherit;
}
.event-container {
  position: relative;
  margin: 0 auto 0px;
  display: block;
  background-color: #F8F9FA;
  height: inherit;
  width: 90%;
  text-align: left;
}
.event-form-box {
  box-sizing: border-box;
  width: 100%;
  display: block;
  margin: auto;
}
.event-form-box div:first-child, .event-form-gate div:first-child {
  border-top: 0.5px solid rgba(0, 0, 0, 0.2);
}
.event-form svg {
  float: right;
  height: 24px;
}
.event-form-gate {
  box-sizing: border-box;
  width: 100%;
  display: block;
  margin: auto;
}
.event-form-title {
  font-size: 16px;
  line-height: 24px;
  padding: 15px 0px;
  color: #000;
  text-transform: capitalize;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
}
.event-form-desc {
  height: 40px;
  max-width: 320px;
  left: 48px;
  top: 315px;
  margin: auto;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #495057;
  padding: 15px 0;
  margin-bottom: 30px !important;
}
.event-top {
  display: flex;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 14px;
  justify-content: center;
}
.event-details {
  padding-right: 37px;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.75px;
  color: #495057;
  width: 100%;
  text-align: center;
}
.gate-details {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.75px;
  color: #495057;
  width: 100%;
  text-align: center;
}
.result-display {
  text-align: center;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.35));
  border-radius: 14px;
  padding: 8px;
  position: relative;
}
.result-display.exit {
  padding: 24px 8px;
}

.close-btn {
  position: absolute;
  top: 55px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-size: 14px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.30);
  width: 94px;
  height: 44px;
  padding: 8px 12px;
  cursor: pointer;
}

.close-btn:hover {
  opacity: 0.7; /* Change opacity on hover */
}

.invalid .result-display {
  padding: 24px 0;
}

.result-display-text,
.result-display-addtext {
  width: 100%;
  text-align: center;
}

.result-display-text {
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.25px;
  font-weight: 600;
  white-space: pre-line;
  margin: 8px 0;
}

.override-btn {
  text-transform: uppercase;
  color: #bf0603;
  background-color: #ffffff;
  height: 40px;
  width: 80%;
  font-size: 20px;
  font-weight: bold;
}
.group-dialog-title .MuiTypography-h6 {
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}
.total-admission,
.already-admitted {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 4px 64px;
}
.ticket-admit-group {
  display: flex;
  width: 300px;
  align-items: center;
  justify-content: space-around;
  margin: auto;
}
.select-admission {
  width: 120px;
  margin: 24px 0px;
}
.admission-admit-btn {
  width: 120px;
  height: 55px;
  background-color: #fca311;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 18px;
}
.admit-all-btn {
  background-color: #bf0306;
  width: 260px;
  margin: 24px auto 64px;
  display: block;
  height: 55px;
  color: #ffffff;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 18px;
}
.result-display-container .result-display svg {
  width: 24px;
  height: auto;
  display: inline;
  margin: 5px;
}
@media only screen and (max-width: 330px) {
  .ticket-admit-group {
    width: 200px;
  }
  .admit-all-btn {
    width: 200px;
  }
}

.video-container .drawingBuffer {
  display: none;
}
.viewport {
  /* position: absolute; */
  /* height: 100%; */
}
.video-container video {
  width: 100%;
  object-fit: cover;
  position: absolute;
  left: 0;
  right: 0;
}
.camera-loading {
  animation: spin 2s linear infinite;
  font-size: 32px;
  color: #14213d;
  position: absolute;
  width: 100vw;
  top: 45vh;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.camera-overlay {
  position: absolute;
  top: 0;
  /* width: 30%; */
  height: 100%;
  margin: auto;
  left: 0;
  right: 0;
  z-index: 0;
}
.scan-container {
  width: 100%;
  position: absolute;
  top: 23%;
}
.scan-info {
  position: relative;
}
.scan-area-border {
  position: relative;
  box-sizing: border-box;
  display: block;
  margin: auto;
  width: 80%;
  height: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  top: 23%;
  z-index: -1;
  box-shadow: 0 0 0 2000px rgb(0 0 0 / 25%);
  border-radius: 50px;
  overflow: hidden;
}
.scan-area-border.short {
  border-radius: 44px;
}
.scan-here-text {
  position: absolute;
  color: #ffffff;
  left: 0;
  right: 0;
  top: 15%;
  font-weight: 600;
}
.enter-barcode-btn {
  display: block;
  box-sizing: border-box;
  padding: 16px 0;
  color: #0067ff;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  width: 100%;
  background-color: #f2f7ff;
  border-radius: 14px;
  margin-top: 10px;
}
.enter-barcode-btn:disabled {
  color: #cce0ff;
  opacity: 0.75;
}
.camera-error {
  position: absolute;
  color: #ffffff;
  top: 50%;
  left: 0;
  right: 0;
}
@media only screen and (max-width: 600px) {
  .viewport video {
    width: 100%;
    height: inherit;
    /* object-fit: cover; */
  }
  .camera-overlay {
    width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  /* .viewport video {
    width: 55%;
  }
  .camera-overlay {
    width: 55%;
  } */
}

.scan-box {
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 14px;
  justify-content: center;
  position: absolute;
  width: 90%;
  z-index: 999;
  left: 5%;
  right: 5%;
}
.scan-menu-btn {
  align-self: flex-start;
  cursor: pointer;
  cursor: pointer;
}
.scan-color {
  width: 12px;
  height: 12px;
  background: #2aca59;
  display: inline-block;
  border-radius: 50%;
  margin-right: 5px;
}
.scan-color.red {
  background: #e95861;
}
.scan-status {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.75px;
  color: #fff;
  width: 100%;
  text-align: center;
}
.scan-override {
  font-weight: bold;
  color: #999999;
  background: none;
  padding: 0;
}
.scan-override.active {
  color: #fff;
}
.MuiIconButton-edgeStart {
  margin: 0;
}
.MuiPaper-root.MuiAppBar-root {
  background-color: transparent;
}
.MuiTypography-h6 {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: bold;
  padding-left: 44px;
}
.MuiPaper-elevation4 {
  box-shadow: none;
}
.event-gate {
  color: #fff;
  font-weight: 700;
  width: 100%;
  margin-top: 12px;
}
.event-gate.short {
  top: 69%;
}
.scanAnimation {
  animation-name: MOVE-BG;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  -webkit-animation-name: MOVE-BG;
  -webkit-animation-duration: 2s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  background: url(/static/media/scanani.75a909a7.png) 0% 0% no-repeat;
  background-size: contain;
  height: 100%;
  width: 100%;
  position: absolute;
}
@keyframes MOVE-BG {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateY(-100%);
  }
}
audio {
  display: none;
}
.scanner-bottom {
  width: 90%;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  bottom: 30px;
}
.last-scanned-result {
  display: block;
  color: #ffffff;
  padding-bottom: 5px;
}
.main-container {
  text-align: center;
  height: 100%;
  width: 100%;
}
.scanner-off {
  width: 100%;
  height: 100%;
  margin: auto;
  background-color: #000000;
}
.scan-btn {
  width: 200px;
  height: 40px;
  font-size: 18px;
  background-color: #fca311;
  color: #ffffff;
  margin-top: 12px;
}
.scan-btn-group {
  width: 144px;
  margin: auto;
  left: 0;
  right: 0;
  border: solid 1px #f2f7ff;
  border-radius: 5px;
  position: absolute;
  z-index: 1;
  top: 60px;
}
.scan-in-btn,
.scan-out-btn {
  width: 71px;
  height: 100%;
  box-sizing: border-box;
  border: 0;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  background-color: transparent;
  color: #f2f7ff;
  padding: 5px 16px;
}
.scan-in-btn.active {
  background-color: #f2f7ff;
  color: #0067ff;
  border-radius: 3px 0 0 3px;
}
.scan-out-btn.active {
  background-color: #f2f7ff;
  color: #0067ff;
  border-radius: 0 3px 3px 0;
}
.barcode-input-dialog .MuiDialog-paperFullWidth {
  border-radius: 15px;
}
.dialog-close-btn {
  text-align: end;
  padding: 12px 12px 0px;
  cursor: pointer;
}
.barcode-input-title {
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
}
.barcode-input-field {
  width: 200px;
  margin: auto;
  display: block;
}
.barcode-submit-btn {
  background-color: #bf0306;
  width: 200px;
  margin: 24px auto 64px;
  display: block;
  height: 40px;
  color: #ffffff;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 18px;
}
.result-display-container {
  width: 100%;
  height:100%;
  border-radius: 0px;
  display: block;
  margin: auto;
  z-index: 1300;
  padding: 15px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}
.big{
  height: 350px;
  bottom: 30%;
}
.result-display-container.valid,
.result-display-container.invalid.reason {
  background-color: #2aca59;
}
.result-display-container.invalid {
  background-color: #e95861;
}
.result-display-container.valid.scanCheck {
  background-color: #fd9245;
}
.permission-denied {
  height: 200px;
  box-sizing: border-box;
  color: #ffffff;
  padding: 64px;
}

.confirm-dialog {
  position: absolute;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.2);
  left: 0;
  right: 0;
  width: 80%;
  height: 130px;
  margin: auto;
  background-color: #ffffff;
}
.confirm-dialog p {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 65px;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
}
.btn-group {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 65px;
}
.dialog-confirm,
.dialog-cancel {
  width: 40%;
  height: 40px;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  display: block;
  font-size: 14px;
  background: #adb5bd;
  border-radius: 14px;
}
.dialog-confirm {
  background: #0067ff;
}
.splash-screen-background {
  background-color: #0052cc;
  width: 100vw;
  height: 100vh;
}
.splash-screen-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
}
.splash-screen-container img {
  margin: 12px 0px;
}

/* POPUP */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.popup {
  width: 100%;
  margin-top: 12px;
  margin-left: 8px;
  margin-right: 8px;
  background-color: #FFFBE6;
  border-radius: 4px;
  border: 1px solid #FFE58F;
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.popup-header {
  width: 100%;
  display: flex;
  align-items: center;
}

.popup-header .popup-title {
  margin-left: 8px;
  flex-grow: 1;
  text-align: left;
}

.popup-footer .popup-submit-btn {
  color: #1890FF;
  font-weight: 600;
  background-color: transparent;
  padding: 0;
  margin-left: 24px;
}
.pin-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(134, 124, 124); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1200; 
  }
  
  .pin-modal {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    text-align: left;
    width: 300px; 
    box-shadow: 8px 4px 8px rgba(114, 112, 112, 0.2); 
  }
  
  .pin-modal-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .pin-input-container {
    margin-bottom: 20px;
  }
  
  .pin-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    text-align: left;
    /* letter-spacing: 8px; */
  }
  
  .pin-error-message {
    color: red;
    font-size: 14px;
    margin-top: 10px;
  }
  
  .pin-submit-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
  }
  
  .pin-submit-button:hover {
    background-color: #0056b3;
  }

  .pin-modal-header {
    margin-bottom: 30px;
    position: relative;
}

.pin-modal-close {
  position: absolute;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.2rem;
    color: #666;
    padding: 0;
    margin: 0;
    top: 0px;
    right: 0px
}

.pin-modal-close:hover {
    color: #333;
}

.pin-input-container {
  position: relative;
}

.password-toggle {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  color: #666;
}

.pin-input {
  padding-right: 40px; /* Make space for the eye icon */
}
.setting-container {
  width: 90%;
  margin: auto;
  background-color: #f8f9fa;
  height: inherit;
}
.mode-selection {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  border-radius: 100px;
  padding: 5px;
}
.mode-container {
  padding-bottom: 15px;
  border-top: 0.5px solid rgba(0, 0, 0, 0.2);
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
}
.setting-title {
  padding: 15px 0 0;
  font-weight: bold;
  font-size: 12px;
  color: #858e96;
  line-height: 20px;
  text-transform: uppercase;
  padding-bottom: 10px;
}
.setting-button,
.validator-button,
.voucher-button {
  width: 100%;
  height: 30px;
  font-size: 16px;
  border-radius: 100px;
  color: #0067ff;
  background-color: transparent;
  font-weight: 700;
}
.setting-button:hover,
.validator-button:hover,
.voucher-button:hover {
  background-color: #0067ff;
  color: #ffffff;
}
.setting-button.active,
.validator-button.active,
.voucher-button.active {
  width: 100%;
  height: 30px;
  background-color: #0067ff;
  font-size: 16px;
  border-radius: 100px;
  color: #ffffff;
  font-weight: 700;
}
.disabled-button-validator {
  width: 100%;
  height: 30px;
  font-size: 16px;
  border-radius: 100px;
  font-weight: 700;
  background-color: #e0e0e0;
  color: #9e9e9e;
}
.emergency-button {
  width: 100%;
  height: 30px;
  background-color: transparent;
  color: #e95861;
  border-radius: 100px;
  font-size: 16px;
  font-weight: 700;
}
.emergency-button.active {
  background-color: #e95861;
  color: #ffffff;
}
.emergency-button:hover {
  background-color: #e95861;
  color: #ffffff;
}

.disabled-button-emergency {
  width: 100%;
  height: 30px;
  border-radius: 100px;
  font-size: 16px;
  font-weight: 700;
  background-color: #e0e0e0;
  color: #9e9e9e;
  cursor: not-allowed;
}

.scanner-info {
  box-sizing: border-box;
  margin: 24px;
  width: 90%;
  color: #000;
  margin-top: 0;
}
.scanner-info p {
  box-sizing: border-box;
  letter-spacing: 0.25px;
  text-align: center;
}
.profile-username {
  color: #343a40;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
}
.profile-scannername {
  color: #495057;
  font-size: 14px;
  line-height: 20px;
}
.app-version {
  position: fixed;
  bottom: 12px;
  color: #ffffff;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 12px;
}
.setting-top {
  display: flex;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 14px;
  justify-content: center;
}
.setting-logout {
  font-weight: 700;
  color: #e95861;
  cursor: pointer;
}
.profile-avatar {
  padding: 27px 0;
  width: 79px;
  height: 79px;
  background-color: #fa8c16;
  border-radius: 50%;
  text-align: center;
  margin: auto;
  margin-bottom: 5px;
  font-size: 20px;
  color: #ffffff;
  font-weight: 600;
  text-transform: uppercase;
}
.MuiButton-root {
  text-transform: capitalize;
}
.setting-content div {
  padding: 25px 0;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
  font-weight: 600;
  color: #495057;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.setting-content div svg {
  color: #66a3ff;
  margin-right: 15px;
  font-size: 21px;
}
.setting-content a,
.setting-top a {
  text-decoration: none;
}
.reset {
  color: #e95861;
  font-weight: 700;
  text-align: center;
  width: inherit;
  text-shadow: 0px 1px 10px rgb(0 103 255 / 10%);
  padding: 20px 0;
}
.reset-container {
  height: 100vh;
  display: block;
  margin: auto;
  width: 90%;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 25%;
}
.reset-title {
  font-size: 32px;
  font-weight: bolder;
  text-transform: capitalize;
  padding: 36px 0px 0;
}
.reset-desc {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  padding: 15px 0 45px 0px;
}
.reset-progress-percent {
  color: #a2a2a2;
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
}
.reset-container .progress {
  border-radius: 100px;
  height: 20px;
  background-color: #385682;
}
.reset-container .progress-bar {
  background-color: #5297ff;
}
.reset-progress-percent.right {
  text-align: right;
}
.setting-fetchLatestConfig {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.override-form {
  width: 100%;
  display: block;
  margin: auto;
  height: inherit;
}
.override-container {
  position: relative;
  margin: 0 auto 0px;
  display: block;
  background-color: #F8F9FA;
  height: inherit;
  width: 90%;
  text-align: left;
}
.override-form-box {
  box-sizing: border-box;
  width: 100%;
  display: block;
  margin: auto;
}
.override-form-box div:first-child, .override-form-gate div:first-child {
  border-top: 0.5px solid rgba(0, 0, 0, 0.2);
}
.override-form svg {
  float: right;
  height: 24px;
}
.override-form-title {
  font-size: 16px;
  line-height: 24px;
  padding: 15px 0px;
  color: #000;
  text-transform: capitalize;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
}
.override-top {
  display: flex;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 14px;
  justify-content: center;
}
.override-details {
  padding-right: 37px;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.75px;
  color: #495057;
  width: 100%;
  text-align: center;
}
.scan-override-text {
  position: absolute;
  top: 105px;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 1;
  color: #fff;
  font-weight: 600;
}
.manual-form {
  width: 100%;
  display: block;
  margin: auto;
  height: inherit;
}
.manual-container {
  position: relative;
  margin: 0 auto 0px;
  display: block;
  background-color: #f8f9fa;
  height: inherit;
  width: 90%;
  text-align: left;
}
.manual-type-container {
  padding-bottom: 15px;
}
.manual-type-selection {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  border-radius: 100px;
  padding: 5px;
}
.manual-type-button {
  width: 100%;
  height: 30px;
  font-size: 14px;
  border-radius: 100px;
  color: #0067ff;
  background-color: transparent;
  font-weight: 700;
  padding: 0px;
}
.manual-type-button:hover {
  background-color: #0067ff;
  color: #ffffff;
}
.manual-type-button.active {
  background-color: #0067ff;
  color: #ffffff;
}
.manual-form-box {
  box-sizing: border-box;
  width: 100%;
  display: block;
  margin: auto;
}
.manual-form-title {
  font-size: 16px;
  line-height: 24px;
  padding: 15px 0px;
  color: #000;
  text-transform: capitalize;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
}
.manual-top {
  display: flex;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 14px;
  justify-content: center;
}
.manual-details {
  padding-left: 28px;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.75px;
  color: #495057;
  width: 100%;
  text-align: center;
}
.manual-override {
  color: #999;
  font-weight: 700;
}
.manual-override.active {
  color: #0067ff;
}
.manual-validate-btn {
  position: absolute;
  width: 100%;
  height: 56px;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  display: block;
  font-size: 16px;
  background: #0067ff;
  border-radius: 14px;
  bottom: 70px;
}
.manual-validate-btn:disabled {
  background: #66a3ff;
  color: rgba(255, 255, 255, 0.3);
}
.scan-manual-text {
  position: absolute;
  top: 105px;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 1;
  color: #fff;
  font-weight: 600;
}
.form-ticket-group {
  width: 90%;
  margin: auto;
}
.form-ticketcode {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #cecece;
  width: 100%;
  height: 40px;
  padding: 6px;
  box-sizing: border-box;
  margin-top: 15px;
}
.manual-container .result-display-container {
  width: 100%;
  left: 0;
  top: auto;
  bottom: 150px;
}
.manual-ticket-group {
  width: 90%;
  margin: auto;
  position: relative;
}
.transaction-tickets-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  font-weight: bold;
  margin-top: 24px;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 6px;
}
.transaction-tickets-display {
  position: relative;
  height: 30vh;
  overflow-y: scroll;
  padding: 4px;
}
.transaction-ticket {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
  height: 50px;
}
.transaction-ticket-id {
  font-weight: 700;
}
.transaction-ticket-status {
  font-style: italic;
  color: rgba(0, 0, 0, 0.5);
}
.transaction-tickets-total {
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 12px;
  font-weight: 700;
  height: 35px;
}
.transaction-tickets-submit {
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 12px;
  font-weight: 700;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.1);
}
.form-totalTicketSubmit {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #cecece;
  width: 40px;
  box-sizing: border-box;
  text-align: center;
}
.confirm-dialog.manual-admit {
  bottom: 25%;
  transform: translateY(-50%);
  font-size: 14px;
}
.admit-aler-msg {
  font-size: 12px;
  text-align: center;
  white-space: pre-line;
}
.manual-input-clear {
  position: absolute;
  bottom: 10px;
  right: 0px;
}
.loading-icon {
  position: absolute;
  top: 0;
  width: 99%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.spin-animation {
  display: flex;
  align-items: center;
  justify-content: center;
  animation: spin 1s infinite linear;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* .result-display-container.scanCheck { */
/* .result-display-container.valid.scanCheck {
  background-color: #FD9245;
} */
.last-scanned-result.manual-entry {
  display: block;
  color: #000;
  padding-bottom: 5px;
  text-align: center;
}
.scanner-btm {
  width: 90%;
  position: absolute;
  right: 0;
  left: 0;
  margin: auto;
  bottom: 150px;
}
.offlineSync-form {
  width: 100%;
  display: block;
  margin: auto;
  height: inherit;
}
.offlineSync-container {
  position: relative;
  margin: 0 auto 0px;
  display: block;
  background-color: #F8F9FA;
  height: inherit;
  width: 90%;
  text-align: left;
}
.offlineSync-form-box {
  box-sizing: border-box;
  width: 100%;
  display: block;
  margin: auto;
}
.offlineSync-form-box div:first-child, .offlineSync-form-gate div:first-child {
  border-top: 0.5px solid rgba(0, 0, 0, 0.2);
}
.offlineSync-complete {
  color: #858E96;
  font-weight: 400;
  font-size: 16px;
  font-style: italic;
  float: right;
  margin-right: 25px;
}
.offlineSync-form svg {
  height: 24px;
  color: #858E96;
  align-self: center;
  /* position: absolute;
  top: 25px;
  right: 0px; */
}
.offlineSync-form-title {
  font-size: 16px;
  line-height: 24px;
  padding: 15px 0px;
  color: #000;
  text-transform: capitalize;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
  /* position: relative; */
  min-height: 80px;
  display:flex;
  justify-content: space-between;
}
.offlineSync-top {
  display: flex;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 14px;
  justify-content: center;
}
.offlineSync-details {
  padding-right: 37px;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.75px;
  color: #495057;
  width: 100%;
  text-align: center;
}
.scan-offlineSync-text {
  position: absolute;
  top: 105px;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 1;
  color: #fff;
  font-weight: 600;
}
.offlineSync-date {
  padding: 15px 0;
  font-weight: 700;
}
.offlineSync-title {
  padding: 10px 10px 0;
  color: #858E96;
  font-weight: 600;
}
.offlineSync-title.active {
  color: #202529;
}
.offlineSync-value {
  font-weight: 600;
  font-size: 32px;
  line-height: 44px;
  color: #263238;
  padding: 10px;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
}
.offlineSync-value.status {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.offlineSync-sync-btn {
  position: absolute;
  width: 100%;
  height: 56px;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  display: block;
  font-size: 16px;
  background: #0067FF;
  border-radius: 14px;
  bottom: 70px;
}
.offlineSync-sync-btn:disabled {
  background: #66A3FF;
  color: rgba(255, 255, 255, 0.3);
}
.report-form {
  width: 100%;
  display: block;
  margin: auto;
  height: inherit;
}
.report-container {
  position: relative;
  margin: 0 auto 0px;
  display: block;
  background-color: #f8f9fa;
  height: inherit;
  width: 90%;
  text-align: left;
}
.report-container-box {
  box-sizing: border-box;
  margin: 20px auto 12px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 10px;
  filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.35));
}
.report-formControl {
  margin: auto;
  width: 250px;
  background-color: #fca311;
}
.report-select::before {
  border-bottom: none;
}
.report-select::after {
  border-bottom: none;
}
.report-select .MuiFilledInput-input {
  padding: 22px 12px 16px;
  text-align: center;
}
.report-data-blocks {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}
.report-pie-chart-block {
  position: relative;
  width: 100%;
  height: 300px;
}
.report-pie-chart-block-pending {
  .ant-skeleton-avatar {
    width: 100% !important;
    height: 100% !important;
  }
  &&& {
    width: 100%;
    height: 100%;
  }
}
.pie-chart-label {
  position: absolute;
  top: 45%;
  transform: translateY(-30%);
  left: 0;
  right: 0;
  font-size: 48px;
  font-weight: 400;
  text-align: center;
  color: #003e99;
  line-height: 26px;
}
.report-pie-chart-block .recharts-default-legend li {
  font-size: 12px;
  font-weight: 600;
  margin-right: 20px !important;
}
.total-ticket-block,
.remain-ticket-block {
  width: 100%;
  border-radius: 10px;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 12px;
  box-sizing: border-box;
}
.scan-ticket-block {
  width: 250px;
  border-radius: 10px;
  margin: 0px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  padding: 12px;
  box-sizing: border-box;
}
.report-ticket-title {
  color: #263238;
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 5px;
}
.report-ticket-number {
  font-weight: 600;
  font-size: 14px;
  color: #858e96;
}
.report-ticket-icon {
  margin-right: 12px;
  color: #fca311;
}
@media only screen and (max-width: 600px) {
  .report-data-blocks {
    display: block;
    width: 100%;
  }
}

.label-text {
  color: #263238;
  font-size: 14px;
  font-weight: 600;
}
.report-top {
  display: flex;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 14px;
  justify-content: center;
}
.report-details {
  padding-right: 37px;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.75px;
  color: #495057;
  width: 100%;
  text-align: center;
}
.report-pie-chart-block .recharts-default-legend li span {
  vertical-align: middle;
}
.report-details-title {
  color: #858e96;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding: 5px 0 0;
}
.report-details-text {
  color: #263238;
  font-weight: 600;
  font-size: 32px;
  padding: 0 0 5px;
}

.report-details-sub-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
}

.report-section-title {
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.report-scan-in-details {
  width: 50%;
  align-items: start;
}

.report-scan-out-details {
  width: 50%;
  align-items: start;
}

.report-gate-name {
  font-weight: 700;
  color: #858e96;
  font-size: 16px;
  padding: 24px 0px;
}

.scan-count-report-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.scan-count-report-name {
  font-weight: 700;
  color: #858e96;
  font-size: 16px;
}

.scan-count-report-details-container {
  padding: 10px 0;
}

.scan-count-report-details-title {
  color: #858e96;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding: 0 0 5px;
}
.scan-count-report-details-list {
  /* width: 100%; */
  display: flex;
  /* justify-content: space-between; */
}

.scan-count-report-details-name {
  color: #263238;
  font-weight: 600;
  font-size: 16px;
  padding: 0 15px 0;
}

.scan-count-report-details-text {
  color: #263238;
  font-weight: 400;
  font-size: 16px;
}

.scan-count-list {
  display: flex;
  flex-direction: column;
}

.scan-count-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 16px;
  padding: 15px 0px;
  color: #000;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
  /* position: relative; */
  min-height: 80px;
}

.scan-count-form-box {
  box-sizing: border-box;
  width: 100%;
  display: block;
  margin: auto;
}

.scan-count-date {
  padding: 15px 0;
  font-weight: 700;
  color: #202529;
}

.scan-count-title {
  padding: 10px 5px 0;
  color: #858E96;
  font-weight: 600;
}

.scan-count-value {
  font-weight: 600;
  font-size: 32px;
  line-height: 44px;
  color: #263238;
  padding: 10px 16px;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
}
.App {
  text-align: center;
}
p {
  margin-bottom: 0px !important;
  margin-block-end: 0;
  margin-block-start: 0;
}

.viewaudit-form {
  width: 100%;
  display: block;
  margin: auto;
  height: inherit;
}
.viewaudit-container {
  position: relative;
  margin: 0 auto 0px;
  display: block;
  background-color: #F8F9FA;
  height: inherit;
  width: 90%;
  text-align: left;
}
.viewaudit-form-box {
  box-sizing: border-box;
  width: 100%;
  display: block;
  margin: auto;
}
.viewaudit-form svg {
  float: right;
  height: 24px;
}
.viewaudit-form-title {
  font-size: 16px;
  line-height: 24px;
  padding: 15px 0px;
  color: #000;
  text-transform: capitalize;
}
.viewaudit-top {
  display: flex;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 14px;
  justify-content: center;
}
.viewaudit-details {
  padding-right: 37px;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.75px;
  color: #495057;
  width: 100%;
  text-align: center;
  margin-top: -5px;
}
.scan-override-text {
  position: absolute;
  top: 105px;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 1;
  color: #fff;
  font-weight: 600;
}
.audit-details-title {
  font-weight: bold;
  margin-bottom: 20px;
}
.audit-info {
  font-size: 14px;
  padding-bottom: 20px;
  padding-top: 20px;
  border-bottom: 1px solid #ccc;
}
.audit-info .status-color {
  color: green;
}
.audit-info .status-color.red {
  color: red;
}
.audit-info div {
  margin-bottom: 5px;
}
.audit-info div.col-5 {
  font-weight: bold;
}
.barcodeaudit-container {
  position: absolute;
  margin: 0 auto 0px;
  width: 90%;
  left: 22px;
}
.barcodeaudit-top {
  display: flex;
  align-items: center;
  padding-top: 30px;
  justify-content: center;
}
.barcodeaudit-details {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.75px;
  color: #fff;
  width: 100%;
  text-align: center;
  z-index: 1;
  margin-top: -10px;
  margin-left: -40px;
}
.barcodeaudit-details.manual-entry {
  color: #000;
}
.back-btn.barcodeaudit {
  color: #fff;
  z-index: 2;
  pointer-events: auto;
}
.back-btn.manual-entry {
  z-index: 2;
  pointer-events: auto;
}
.video-container.barcodeaudit .scan-here-text {
  width: 100%;
  text-align: center;
}
.scan-info {
  color: #fff;
  text-align: center;
  margin-top: 20px;
  padding: 0px 30px;
}
html {
  height: 100%;
}
body {
  height: 100%;
  margin: 0;
  font-family: Inter, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-y: contain;
  background-color:#F8F9FA !important;
}

body::after {
  content: "";
  position: fixed;
  width: 100%;
  height: 50%;
  bottom: 0;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  z-index: -1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  text-align: left;
  height: 100%;
}
button {
  border: none;
  padding: 8px 16px;
  text-decoration: none;
}
button:hover {
  text-decoration: none;
  cursor: pointer;
}
.back-btn {
  font-weight: 700;
  color: #0067FF;
  align-self: flex-start;
  cursor: pointer;
  margin-top: -5px;
}
a {
  color: #333333 !important;
  text-decoration: none;
}
a:hover {
  text-decoration: none !important;
}

