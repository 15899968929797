.result-display {
  text-align: center;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.35));
  border-radius: 14px;
  padding: 8px;
  position: relative;
}
.result-display.exit {
  padding: 24px 8px;
}

.close-btn {
  position: absolute;
  top: 55px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-size: 14px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.30);
  width: 94px;
  height: 44px;
  padding: 8px 12px;
  cursor: pointer;
}

.close-btn:hover {
  opacity: 0.7; /* Change opacity on hover */
}

.invalid .result-display {
  padding: 24px 0;
}

.result-display-text,
.result-display-addtext {
  width: 100%;
  text-align: center;
}

.result-display-text {
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.25px;
  font-weight: 600;
  white-space: pre-line;
  margin: 8px 0;
}

.override-btn {
  text-transform: uppercase;
  color: #bf0603;
  background-color: #ffffff;
  height: 40px;
  width: 80%;
  font-size: 20px;
  font-weight: bold;
}
.group-dialog-title .MuiTypography-h6 {
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}
.total-admission,
.already-admitted {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 4px 64px;
}
.ticket-admit-group {
  display: flex;
  width: 300px;
  align-items: center;
  justify-content: space-around;
  margin: auto;
}
.select-admission {
  width: 120px;
  margin: 24px 0px;
}
.admission-admit-btn {
  width: 120px;
  height: 55px;
  background-color: #fca311;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 18px;
}
.admit-all-btn {
  background-color: #bf0306;
  width: 260px;
  margin: 24px auto 64px;
  display: block;
  height: 55px;
  color: #ffffff;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 18px;
}
.result-display-container .result-display svg {
  width: 24px;
  height: auto;
  display: inline;
  margin: 5px;
}
@media only screen and (max-width: 330px) {
  .ticket-admit-group {
    width: 200px;
  }
  .admit-all-btn {
    width: 200px;
  }
}
