.pin-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(134, 124, 124); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1200; 
  }
  
  .pin-modal {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    text-align: left;
    width: 300px; 
    box-shadow: 8px 4px 8px rgba(114, 112, 112, 0.2); 
  }
  
  .pin-modal-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .pin-input-container {
    margin-bottom: 20px;
  }
  
  .pin-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    text-align: left;
    /* letter-spacing: 8px; */
  }
  
  .pin-error-message {
    color: red;
    font-size: 14px;
    margin-top: 10px;
  }
  
  .pin-submit-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
  }
  
  .pin-submit-button:hover {
    background-color: #0056b3;
  }

  .pin-modal-header {
    margin-bottom: 30px;
    position: relative;
}

.pin-modal-close {
  position: absolute;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.2rem;
    color: #666;
    padding: 0;
    margin: 0;
    top: 0px;
    right: 0px
}

.pin-modal-close:hover {
    color: #333;
}

.pin-input-container {
  position: relative;
}

.password-toggle {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  color: #666;
}

.pin-input {
  padding-right: 40px; /* Make space for the eye icon */
}